




















































import { computed, defineComponent, ref } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import {
  ChangePasswordMenu,
  ContextMenu,
  Menu,
} from "@/apps/core/models/contextMenu";
import PengelolaVM, { Pengelola } from "../models/pengelola";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { ToastProgrammatic as Toast } from "buefy";
import useViewModel from "@/apps/core/modules/useViewModel";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import APP_CONFIG from "@/apps/core/modules/config";
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: "PengelolaMe",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
  },
  setup() {
    const paramsId =
      router.currentRoute.params.id ?? accountStateRef.me?.pengelola?.id;
    const pengelolaVM = new PengelolaVM();
    const { viewModelRef, instance } = useViewModel(paramsId, pengelolaVM);
    const pengelola = instance as Pengelola;

    const contextMenu = new ContextMenu([
      new Menu("Ubah Profil", "pengelola-change", undefined, { id: paramsId }),
      new ChangePasswordMenu(),
    ]);

    const fotoDataRef = ref(null);
    const canUpload = computed(() => pengelola.id === paramsId);
    const isUploading = ref(false);
    const uploadPhoto = new UploadPhoto("pengelola", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const saveFoto = async (url: string | null) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/pengelola/${paramsId}/`;
      const data = { foto: url };
      try {
        const response = await axios.patch(uploadDocUrl, data);
        pengelola.foto = response.data.foto;
        accountStateRef.me.pengelola = pengelola;
        fotoDataRef.value = null;
        Toast.open("Foto berhasil di upload.");
      } catch {
        Toast.open("Foto gagal di upload.");
      }
    };

    const uploadFile = (foto: File) => {
      uploadPhoto.requestAWS(foto, saveFoto);
    };

    return {
      // Data
      canUpload,
      contextMenu,
      pengelola,
      fotoDataRef,
      isUploading,
      // imageUrl,
      viewModelRef,

      // Method
      toUserDateFormat,
      uploadFile,
    };
  },
});
