



























































































































































import { defineComponent } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import PengelolaFM from "../models/pengelolaForm";
import MeVM, { Me } from "@/apps/accounts/models/me";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";
import { isEmail } from "@/apps/core/modules/utils/text";
import { Pengelola } from "../models/pengelola";
import { listVM } from "../modules/store";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "PengelolaForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  props: {
    headerText: String,
  },
  setup() {
    const requiredFields = ["nama", "noAnggota", "email"];
    const paramsId = router.currentRoute.params.id;
    const nonReqFields = ["tglLahir", "agama", "gender", "noHp", "panggilan", "appuserIsActive"];
    const form = new PengelolaFM(requiredFields, nonReqFields);
    const composition = useFormModel(form, paramsId);
    const pengelola = composition.instance as Pengelola;

    const validateEmail = () => {
      const email = pengelola.email;
      if (email) {
        if (!isEmail(email)) {
          form.errorMap.email = "Isikan dengan format email.";
          return;
        }
      } else {
        pengelola.email = null;
      }
      form.validate("email");
    };

    const pengelolaId = accountStateRef.me.pengelola?.id;

    const savePengelola = async () => {
      const isCreate = !paramsId;
      const saveContext = isCreate ? SaveContext.Create : SaveContext.Update;
      await form.save(saveContext, !isCreate).then(async () => {
        if (form.getIsValid().value) {
          if (pengelola.id === accountStateRef.me.pengelola?.id) {
            const meVM = new MeVM(accountStateRef.me as Me);
            await meVM.fetch();
            // router.replace({
            //   name: "pengelola-me",
            // });
          } else if (isCreate) {
            router.replace({
              name: "pengelola-detail",
              params: { id: pengelola.id ?? "" },
            });
          }
          listVM.setRefreshRequest("pengelola");
        }
      });
    };

    return {
      // Data
      pengelolaId,

      // Composition
      ...composition,

      // Method
      savePengelola,
      validateEmail,
    };
  },
});
