import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";


class Pengelola extends PlainModel {
  nama: string | null = null;
  panggilan: string | null = null;
  foto: string | null = null;
  email: string | null = null;
  appuserId: string | null = null;
  appuserIsActive: boolean | null = null;
  tglLahir: string | null = null;
  gender: string | null = null;
  noHp: string | null = null;
  unitKerja: Record<string, string> | null = null;
  purnaTugas: boolean | null = null;
  jabatan: Record<string, string> | null = null;
  agama: string | null = null;
  statusKaryawan: string | null = null;
  noAnggota: string | null = null;
  dokumenpengelola: Array<Record<string, string>> | null = null;
}

class PengelolaAPI extends API {
  static modelPath = "/pengelola/";

  constructor() {
    super(PengelolaAPI.modelPath);
  }
}

export default class PengelolaVM extends ViewModel {
  constructor() {
    super(new PengelolaAPI(), new Pengelola());
  }
}


export { Pengelola, PengelolaAPI };