import { API } from "@/apps/core/api";
import { ToastProgrammatic as Toast } from "buefy";
import axios from "axios";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class PengelolaSummary extends PlainModel {
  anggota: string | null = null;
  setoranMandiri: string | null = null;
  totalPotonganGaji: string | null = null;
  pengajuan: string | null = null;
}

class PengelolaSummaryAPI extends API {
  static modelPath = "/pengelola/summary-dashboard/";

  constructor() {
    super(PengelolaSummaryAPI.modelPath);
  }
}

class PengelolaSummaryVM extends ViewModel {
  constructor() {
    super(new PengelolaSummaryAPI(), new PengelolaSummary());
  }

  async fetch(): Promise<void> {
    try {
      const url = this.api.getAPIUrl();
      const response = await axios.get(url);
      this.instance.loadData(response.data);
    } catch (error) {
      Toast.open("Data gagal dimuat.");
    }
  }
}

export { PengelolaSummary, PengelolaSummaryVM };
