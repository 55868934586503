import accountStateRef from "@/apps/accounts/modules/store";
import router from "@/router";

const DEFAULT_ICONS: Record<string, string> = {
  Hapus: "trash",
  Keluar: "sign-out-alt",
  "Bantuan": "info-circle",
  Ubah: "edit",
  "Ubah Profil": "edit",
  "Ubah Informasi": "edit",
  "Ubah Sandi": "edit",
  Verifikasi: "check",
};

class Menu {
  label: string;
  icon: string | undefined;
  routeName: string;
  enabled = true;
  params = {};

  constructor(label: string, routeName: string, icon?: string, params?: Record<string, string>) {
    this.label = label;
    this.routeName = routeName;
    if (icon) {
      this.icon = icon;
    } else {
      if (Object.keys(DEFAULT_ICONS).includes(label)) {
        this.icon = DEFAULT_ICONS[label];
      }
    }

    if (params) this.params = params;
  }

  onClick(): void {
    router.push({ name: this.routeName, params: this.params });
  }
}

class ContextMenu {
  list: Array<Menu>;
  menuMap: Record<string, Menu> = {};

  constructor(list: Array<Menu>) {
    this.list = list;
    for (const menu of this.list) {
      this.menuMap[menu.label] = menu;
    }
  }
}

class ChangePasswordMenu extends Menu {
  constructor() {
    super("Ubah Sandi", "change-password");
  }
}

class KeluarMenu extends Menu {
  onClick(): void {
    accountStateRef.me.logout();
    router.push({ name: "login" });
  }
}

class AboutMenu extends Menu {
  constructor() {
    super("Bantuan", "bantuan");
  }
}

export { AboutMenu, ChangePasswordMenu, ContextMenu, KeluarMenu, Menu };
