<template>
  <section class="info-tiles">
    <div class="container">
      <!-- <div class="card"> -->
        <div class="columns no-margin is-mobile has-text-centered box-notif">
          <div class="column no-margin is-half">
            <div class="tile is-ancestor">
              <card-info
                :subtitle="toStringFormat(summary.anggota)"
                info="Total Anggota"
                targetName="anggota-list"
                appName="anggota"
              ></card-info>
              <card-info
                :subtitle="toStringFormat(summary.pengajuan)"
                info="Pengajuan Pinjaman"
                targetName="pinjaman-list"
                appName="pinjaman"
              ></card-info>
            </div>
          </div>

          <div class="column no-margin is-half">
            <div class="tile is-ancestor">
              <card-info
                :subtitle="'Rp. ' + formatRupiah(summary.totalPotonganGaji)"
                info="Total Potongan Gaji"
                appName="tabungan"
              ></card-info>
              <card-info
                :subtitle="toStringFormat(summary.setoranMandiri)"
                info="Setoran Mandiri"
                targetName="setoran-mandiri-list"
                appName="tabungan"
              ></card-info>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </section>
</template>


<script>
import { onMounted, reactive } from "@vue/composition-api";
import { PengelolaSummaryVM } from "../models/summary";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { setRefreshRequest as setRefreshRequestSM } from "@/apps/tabungan/modules/stores/setoranMandiri";
import { setRefreshRequest as setRefreshRequestPinj } from "@/apps/pinjaman/modules/stores/pengajuan";

export default {
  name: "PengelolaHome",
  components: {
    // eslint-disable-next-line
    CardInfo: () => import("../components/CardInfo.vue"),
  },
  // eslint-disable-next-line
  setup() {
    const summaryVM = new PengelolaSummaryVM();
    const viewModelRef = reactive(summaryVM);
    onMounted(async () => {
      viewModelRef.isLoading = true;
      await viewModelRef.fetch();
      viewModelRef.isLoading = false;
      setRefreshRequestSM("dashboard");
      setRefreshRequestPinj("dashboard");
    });

    const toStringFormat = (value) => value? value.toString(): "0";

    return {
      // Data
      viewModelRef,
      summary: viewModelRef.instance,

      // Method
      formatRupiah,
      toStringFormat
    };
  },
};
</script>


<style scoped lang="scss">
div.container {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.5rem !important;
}

.empty-card {
  padding: 0rem;
}

// ::v-deep .box-notif article.box {
//   background-color: #f1f1f1;
// }

::v-deep .box-notif {
  padding: 0px 10px 0px 10px;
}

::v-deep .box-notif:first-of-type {
  padding-top: 10px;
}

::v-deep .box-notif:last-of-type {
  padding-bottom: 10px;
}

.stase-date {
  padding: 0.75rem;
}

.is-black {
  color: #000000 !important;
}

.card-header-title {
  font-weight: 500;
}

hr {
  border: none;
  border-top: 1px solid;
  color: #dbdbdb;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
